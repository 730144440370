@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.cdnfonts.com/css/eugusto-free-personal-use");
@import url("https://fonts.cdnfonts.com/css/indulta");
@import url("https://fonts.cdnfonts.com/css/albegos");
@import url("https://fonts.cdnfonts.com/css/magden");
@import url("https://fonts.cdnfonts.com/css/ranika");
@import url("https://fonts.googleapis.com/css2?family=Baumans&family=Bricolage+Grotesque:opsz,wght@12..96,200..800&family=Mina:wght@400;700&family=Space+Mono:ital,wght@0,400;0,700;1,400;1,700&family=Syne:wght@400..800&display=swap");

body {
  margin: 0;
  font-family: "Bricolage Grotesque", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html {
  scroll-behavior: smooth;
  --cursor-color: #333;
}

textarea {
  border-width: 0;
}

.gradient {
  position: relative; /* Necessario per gestire i livelli con z-index */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100vh;
  width: 100vw;
  object-fit: cover;
}

.gradient::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-image: linear-gradient(
      90deg,
      rgba(209, 209, 209, 0.2) 1px,
      transparent 1px
    ),
    linear-gradient(rgba(228, 228, 228, 0.2) 1px, transparent 1px);
  background-size: 120px 120px;
  z-index: 0; /* La griglia sarà dietro al contenuto */
  pointer-events: none; /* Non interferisce con gli eventi */
}

#hero {
  position: relative; /* Il testo è posizionato relativo alla griglia */
  z-index: 1; /* Il testo sarà sopra la griglia */
}

.content-over-grid {
  position: relative;
  z-index: 1; /* L'immagine rimane sopra la griglia */
}

/* CIRCLE */
.outer-circle {
  width: 110px; /* Dimensione del cerchio esterno */
  height: 110px; /* Dimensione del cerchio esterno */
  background-color: black;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden; /* Nascondi il contenuto in eccesso */
}

.inner-circle {
  width: 50px; /* Dimensione del cerchio interno */
  height: 50px; /* Dimensione del cerchio interno */
  background-color: purple;
  border-radius: 50%;
  position: absolute;
  z-index: 1; /* Porta il cerchio interno sopra il cerchio esterno */
}

.svg-circle {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2; /* Assicura che il testo sia sopra il cerchio interno */
}

.circle-text {
  font-size: 12px; /* Dimensione del carattere */
  fill: white; /* Colore del testo */
  text-transform: uppercase;
  letter-spacing: 2px; /* Spaziatura delle lettere */
}

/* Riduzione del 30% per width fino a 768px */
@media (max-width: 760px) {
  .outer-circle {
    width: 77px;
    height: 77px;
  }

  .inner-circle {
    width: 35px;
    height: 35px;
  }

  .circle-text {
    font-size: 11px;
    letter-spacing: 1.4px;
    font-weight: bold;
  }
}

@keyframes rotate-text {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

svg {
  animation: rotate-text 10s linear infinite; /* Applica l'animazione */
}

/* SCROLLER */
.scroller {
  position: absolute;
  bottom: 0;
  width: 100vw;
}

.scroller__inner {
  padding-block: 1rem;
  display: flex;
  flex-wrap: wrap;
}

.scroller[data-animated="true"] .scroller__inner {
  width: max-content;
  flex-wrap: nowrap;
  animation: scroll var(--_animation-duration, 40s)
    var(--_animation-direction, forwards) linear infinite;
}

.scroller[data-direction="right"] {
  --_animation-direction: reverse;
}

.scroller[data-direction="left"] {
  --_animation-direction: forwards;
}

.scroller[data-speed="fast"] {
  --_animation-duration: 20s;
}

.scroller[data-speed="slow"] {
  --_animation-duration: 60s;
}

@keyframes scroll {
  to {
    transform: translate(calc(-50% - 0.5rem));
  }
}

.overlay {
  height: 0;
  width: 100%;
  position: fixed;
  z-index: 9999;
  left: 0;
  top: 0;
  overflow: hidden;
  transition: height 0.5s;
}

.overlay.open {
  height: 100%;
}

.overlay-content {
  position: absolute;
  width: 100%;
}
